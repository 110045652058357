﻿import { createApp } from 'vue';
import VueCustomInputComponent from '../../Components/vue-custom-input-component.vue';
import VueCustomViviendasCatalogoComponent from '../../Components/vue-custom-viviendas-catalogo-component.vue';

createApp({
    components: {
        VueCustomInputComponent,
        VueCustomViviendasCatalogoComponent
    }
}).mount('#app');

