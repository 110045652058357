<template>
    <input    
        :id="id"
        :name="name"
        :value="value"
        :type="type"   
        :placeholder="placeHolder"
        :class="className"
    />
</template>

<script>
    import { onMounted } from 'vue';

    export default {
        name: "vue-custom-input",
        props: {
            name: {
                default: '',
                type: String,
                required: true,
            },
            placeHolder: {
                type: String
            },
            value: {
                type: String,
            },
            type: {
                default: 'text',
                type: String,
            },
            className: {
            },
            attributes: {
            },
        },
        setup(props, context) {
            const id = props.name.replace(/[\[\]]/gi, '__');

            onMounted(() => {
                let selector = '#' + id;
                let component = $(selector);
                let attributes = props.attributes;

                Object.keys(attributes).forEach(keyAttribute => {
                    let attribute = attributes[keyAttribute];
                    if (typeof attribute === 'object') {
                        Object.keys(attribute).forEach(key => {
                            component.attr(keyAttribute + '-' + key, attribute[key]);
                        })
                    } else {
                        component.attr(keyAttribute, attribute);
                    }
                });
            });

            return {id}
        },
        components: {
        },
    }
</script>