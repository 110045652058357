<template>
    <div class="container col-12">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div v-for="vivienda in viviendas" class="col">
                <div class="card">
                    <div class="card-img-top">
                        <div :id="'carousel_' + vivienda.folio" class="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div :class="'carousel-indicators indicator-initial-' + fotosContadorReiniciar()">
                                <button v-for="foto in vivienda.fotos.fotos_aviso_vendida"
                                        type="button"
                                        :data-bs-target="'#' + 'carousel_' + vivienda.folio"
                                        :data-bs-slide-to="fotosContadorNumero()"
                                        :class="(fotosContadorNumero() == 0 ? 'active' : '')"
                                        :aria-current="(fotosContadorNumero() == 0 ? 'true' : '')"
                                        :aria-label="'Slide ' + fotosContadorIncrementar()">
                                </button>

                                <button v-for="foto in vivienda.fotos.fotos_muestra_publica_entrega"
                                        type="button"
                                        :data-bs-target="'#' + 'carousel_' + vivienda.folio"
                                        :data-bs-slide-to="fotosContadorNumero()"
                                        :class="(fotosContadorNumero() == 0 ? 'active' : '')"
                                        :aria-current="(fotosContadorNumero() == 0 ? 'true' : '')"
                                        :aria-label="'Slide ' + fotosContadorIncrementar()">
                                </button>

                            </div>
                            <div :class="'carousel-inner item-initial-' + fotosContadorReiniciar()">
                                <div v-for="foto in vivienda.fotos.fotos_aviso_vendida" :class="'carousel-item ' + (fotosContadorNumero() == 0 ? 'active' : '')">
                                    <img :src="foto.url_large" class="d-block w-100" alt="...">
                                    <div class="carousel-caption carousel-caption-top">
                                        <p>SKU: DIL{{vivienda.folio}}PAG</p>
                                    </div>
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Vendida y Entregada</h5>
                                        <ul>
                                            <li>Ejemplo: {{foto.lugar}}</li>
                                            <li>Imágen: {{fotosContadorIncrementar()}} de {{  vivienda.fotos.fotos_aviso_vendida.length +  vivienda.fotos.fotos_muestra_publica_entrega_fachada.length + vivienda.fotos.fotos_muestra_publica_entrega.length   }}</li>
                                        </ul>
                                    </div>
                                    <div class="carousel-caption d-block d-md-none">
                                        <h5>Vendida y Entregada</h5>
                                        <p>Ejemplo: {{foto.lugar}} - Imágen: {{fotosContadorIncrementar()}} de {{  vivienda.fotos.fotos_aviso_vendida.length +  vivienda.fotos.fotos_muestra_publica_entrega_fachada.length + vivienda.fotos.fotos_muestra_publica_entrega.length   }}</p>
                                    </div>
                                </div>
                                <div v-for="foto in vivienda.fotos.fotos_muestra_publica_entrega" :class="'carousel-item ' + (fotosContadorNumero() == 0 ? 'active' : '')">
                                    <img :src="foto.url_large" class="d-block w-100" alt="...">
                                    <div class="carousel-caption carousel-caption-top">
                                        <p>SKU: DIL{{vivienda.folio}}PAG</p>
                                    </div>
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Vendida y Entregada</h5>
                                        <ul>
                                            <li>Ejemplo: {{foto.lugar}}</li>
                                            <li>Imágen: {{fotosContadorIncrementar()}} de {{  vivienda.fotos.fotos_aviso_vendida.length +  vivienda.fotos.fotos_muestra_publica_entrega_fachada.length + vivienda.fotos.fotos_muestra_publica_entrega.length   }}</li>
                                        </ul>
                                    </div>
                                    <div class="carousel-caption d-block d-md-none">
                                        <h5>Vendida y Entregada</h5>
                                        <p>Ejemplo: {{foto.lugar}} - Imágen: {{fotosContadorIncrementar()}} de {{  vivienda.fotos.fotos_aviso_vendida.length +  vivienda.fotos.fotos_muestra_publica_entrega_fachada.length + vivienda.fotos.fotos_muestra_publica_entrega.length   }}</p>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" :data-bs-target="'#' + 'carousel_' + vivienda.folio" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Anterior</span>
                            </button>
                            <button class="carousel-control-next" type="button" :data-bs-target="'#' + 'carousel_' + vivienda.folio" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Siguiente</span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Vivienda Vendida y Entregada</h5>
                        <p class="card-text">
                            <ul>
                                <li>Terreno m<sup>2</sup>: {{vivienda.propiedades.m2_terreno}}</li>
                                <li>Construcción m<sup>2</sup>: {{vivienda.propiedades.m2_construccion}}</li>
                                <li>Niveles: {{vivienda.propiedades.niveles}}</li>
                                <li>Recámaras: {{vivienda.propiedades.recamaras}}</li>
                                <li>Baños Completos: {{vivienda.propiedades.banios}}</li>
                                <li>Medios Baños: {{vivienda.propiedades.medios_banios}}</li>
                                <li>Lugares de estacionamiento: {{vivienda.propiedades.espacios_estacionamiento}}</li>
                            </ul>
                        </p>
                        <p class="card-text">Esta vivienda se entregó en {{vivienda.estado}}, {{vivienda.municipio}}. Las imágenes que se muestran hacen referencia únicamente al estado en el que se entregó esta vivienda en específico.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div v-if="isLoading" class="row justify-content-md-center"> Loading ... </div>
            <div v-else-if="error" class="row justify-content-md-center"> {{ error.message }} <button class="btn btn-danger btn-lg" @click="getViviendas">Intentar Cargar de Nuevo las Viviendas</button> </div>
            <!--<div v-else-if="muestraCargada" class="row justify-content-md-center"><a href="./viviendas" class="btn btn-success btn-lg">Mostrar más Viviendas!!!</a></div>-->
            <div v-else-if="!muestraCargada" class="row justify-content-md-center"><button class="btn btn-primary btn-lg" @click="getViviendas">CLICK AQUÍ - Para Mostrar Viviendas!!!</button></div>
        </div>
        
    </div>    
</template>

<script>
    import { ref } from 'vue';

    export default {
        name: "vue-custom-viviendas-catalogo",
        props: {
            name: {
                default: '',
                type: String,
            },
            className: {
                default: '',
                type: String,
            },
            attributes: {
                default: '',
                type: String,
            },
            autoload: {
                default: false,
                type: Boolean
            },
            estadoNombre: {
                default: '',
                type: String,
            },
            municipioNombre: {
                default: '',
                type: String,
            },
            estadoId: {
                default: 0,
                type: BigInt,
            },
            municipioId: {
                default: 0,
                type: BigInt,
            },
        },
        setup(props, context) {
            var error = ref(null);
            var isLoading = ref(false);
            var muestraCargada = ref(false);
            
            var viviendas = ref(null);
            const getViviendas = async () => {
                //console.log("getViviendas", "START");
                isLoading.value = true;
                $(".loader-data").css({ visibility: "visible", opacity: "1" });
                try {
                    $.ajax({
                        type: "GET",
                        url: "/viviendas-entregadas",
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                    }).done(function (result) {
                        var resultJson = JSON.stringify(result);
                        //console.log(resultJson);
                        var resultObject = JSON.parse(resultJson);
                        //console.log(resultObject);                                                
                        viviendas.value = resultObject;
                        isLoading.value = false;
                        muestraCargada.value = true;
                        $(".loader-data").css({ visibility: "hidden", opacity: "0" });
                        //console.log("getViviendas", "END ASYNC");
                    });
                    //console.log("getViviendas", "END SYNC");
                } catch (e) {
                    error.value = e;
                    $(".loader-data").css({ visibility: "hidden", opacity: "0" });
                    console.log("getViviendas", "ERROR");
                    console.log('error', error);
                }
            };
            if (props.autoload) {
                getViviendas();
            }
            

            var fotoContador = 0;
            const fotosContadorReiniciar = () => {
                try {
                    fotoContador = 0;
                    return fotoContador;
                } catch (e) {
                    error.value = e;
                    console.log('error', error);
                }
            };

            const fotosContadorNumero = () => {
                try {
                    return fotoContador;
                } catch (e) {
                    error.value = e;
                    console.log('error', error);
                }
            };

            const fotosContadorIncrementar = () => {
                try {
                    fotoContador++;
                    return fotoContador;
                } catch (e) {
                    error.value = e;
                    console.log('error', error);
                }
            };

            return {
                isLoading,
                muestraCargada,
                error,
                viviendas,
                getViviendas,
                fotoContador,
                fotosContadorReiniciar,
                fotosContadorNumero,
                fotosContadorIncrementar
            };
        },
        components: {
        },
    }
</script>

<style>
    .carousel-caption-top {
        top: 0;
        bottom: auto;
    }
</style>